import { useRef } from "react";
import { useFormContext } from "../../../contexts/FormContext";
import { Icon, List } from "getbasecore/Atoms";
import { BtnSimple } from "../../atoms/BtnSimple/BtnSimple";
import { Tooltip } from "react-tooltip";
import PdfMilenium from "../../../pdf/NPI+Dental+Milenium+sin+copago.pdf";
import PdfPremium from "../../../pdf/NPI+Dental+Premium+Sin+copago.pdf";
import { ClickCard } from "../../molecules/ClickCard/ClickCard";
import { ProgressSteps } from "../../atoms/ProgressSteps/ProgressSteps";
import { Hero } from "../../organisms/Hero/hero";
import {
  securanceMilenium,
  securancePremium,
  priceMilenium2025,
  pricePremium2025,
  pricePack2025
} from "../../../data/constants";
import "./step1.scss";

export const Step1 = ({ onNext }) => {
  const { formData, setFormData, setTotalPrice } = useFormContext();

  const mileniumPrice =  priceMilenium2025;
  const premiumPrice = pricePremium2025;
  const packPrice = pricePack2025;

  const handleSecuranceMilenium = () => {
    setTotalPrice(mileniumPrice);
    setFormData({ ...formData, securance: securanceMilenium, price: mileniumPrice, step: formData.step + 1 });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  };

  const handleSecurancePremium = () => {
    setTotalPrice(premiumPrice);
    setFormData({ ...formData, securance: securancePremium, price: premiumPrice, step: formData.step + 1 });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  };

  const scrollRef = useRef(null);
  const scrollMeTo = (ref) => {
    ref.current.scrollIntoView();
  };

  return (
    <>
      <Hero
        css={"step1"}
        imgCss={"imgCss"}
        imgSrc={"img/logosanitassinfondo.png"}
        bkg={"./img/familia-joven-hijo-pequeño-parque1.jpg"}>
        <div className="hero-card">
          <div className="container--grid">
            <p className="h1">Condiciones especiales en tu seguro dental para empleados y familiares</p>
            <p className="h6.2">
              Recuerda que con el seguro dental mantienes el 15% de descuento por ser empleado y familiar{" "}
              <button type="button" className="hero-tooltip">
                <p className="link-5">(1)</p>
              </button>
              <Tooltip anchorSelect=".hero-tooltip" place="top" events={["click"]}>
                <small className="react-tooltip__content">
                  Descuento aplicable a tratamientos iniciados en los Centros Dentales Milenium identificados en
                  www.sanitas.es/clinicasdentales. Descuento aplicable sobre las tarifas correspondientes al seguro de
                  Sanitas contratado o, en el caso de no tener contratado ningún seguro, a aquellas tarifas que le
                  fueran aplicables. No acumulable a otras promociones.
                </small>
              </Tooltip>
              .
            </p>

            <div className="btn-group hero">
              <BtnSimple
                type="button"
                css="btn-simple--2 btn-hero-contract"
                aria=""
                onClick={() => scrollMeTo(scrollRef)}>
                Contrata tu seguro dental
                <Icon css="icon--xs" icon="arrow-right" />
              </BtnSimple>
              <BtnSimple
                type="button"
                css="btn-simple--1 btn-hero-clinic"
                aria=""
                onClick={() =>
                  window.open(
                    "https://www.sanitas.es/sanitas/seguros/es/particulares/dental/clinicas-dentales-milenium/index.html",
                    "_blank"
                  )
                }>
                Ver clínicas
                <Icon css="icon--xs" icon="arrow-right" />
              </BtnSimple>
            </div>
          </div>
        </div>
      </Hero>
      <div className="container container--grid step">
        <p className="h3 section-header" ref={scrollRef}>
          Elige tu oferta
        </p>
        <List css="click-cards click-cards__step1 ">
          <ClickCard
            css="btn-simple--1"
            h2="Seguro Dental Milenium"
            btnTxt="Contratar"
            iconbtn="arrow-right"
            buttonDiv="click-cards__button"
            name="Milenium"
            onClick={handleSecuranceMilenium}>
            <div>
              <p className="h3 milenium">Seguro Dental Milenium</p>
              <ul className="click-cards__services">
                <li>
                  <Icon icon="paper-blue" css="icon--sm"></Icon>
                  <a href={PdfMilenium} target="_blank" rel="noreferrer">
                    <p className="h5.2 link3">54 servicios incluidos</p>
                  </a>
                </li>
                <li>
                  <Icon icon="chair-blue" css="icon--sm"></Icon>
                  <p className="h5.2">Acceso a la red de más de 200 Clínicas Dentales Milenium</p>
                </li>
              </ul>
            </div>
            <div className="click-cards__prices">
              <div>
                <p className="h6-price">
                  Precio individual{" "}
                  <button type="button" className="premium-price-tooltip">
                    <p className="link">(3)</p>
                  </button>
                  <Tooltip anchorSelect=".premium-price-tooltip" place="top" events={["click"]}>
                    <small className="react-tooltip__content">
                      Prima neta mensual por asegurado a la que se aplicará el recargo del Consorcio de Compensación de
                      Seguros (0,15% sobre la prima neta anual. Primas válidas para contrataciones formalizadas entre el
                      01/01/2025. y el 31/12/2025, ambos inclusive. Primas netas aplicables hasta el 01/12/2025. Primas
                      válidas para pólizas independientes. Permanencia anual.
                    </small>
                  </Tooltip>{" "}
                </p>
                <p className="h6-offer">
                  Precio sin descuento <s>12,95€</s>{" "}
                </p>
                <p className="h3.2 ">{mileniumPrice}€</p>
              </div>
            </div>
          </ClickCard>

          <ClickCard
            css="btn-simple--2"
            btnTxt="Contratar"
            buttonDiv="click-cards__button"
            iconbtn="arrow-right"
            name="premium"
            onClick={handleSecurancePremium}>
            {" "}
            <div>
              <p className="h3 premium">Seguro Dental Premium</p>
              <ul className="click-cards__services">
                <li>
                  <Icon icon="paper-darkblue" css="icon--sm"></Icon>
                  <p className="h5.2 link3">
                    <a href={PdfPremium} target="_blank" rel="noreferrer">
                      72 servicios con empastes y reconstrucciones incluidos
                    </a>{" "}
                    <button type="button" className="premium-tooltip">
                      <p className="link">(2)</p>
                    </button>
                    <Tooltip anchorSelect=".premium-tooltip" place="top" events={["click"]}>
                      <small className="react-tooltip__content">
                        El asegurado tiene incluido en su póliza –sin coste, salvo aquellos asegurados que tengan
                        contratado la modalidad del producto con copago- la realización de dos de los siguientes
                        tratamientos cada año de vigencia de la misma: empastes, reconstrucciones y obturaciones en
                        diente. Con independencia del número de anualidades que transcurran durante el periodo total de
                        vigencia de la póliza, el asegurado podrá realizarse una suma total de 8 de los anteriores
                        tratamientos. Si el asegurado precisa de algún tratamiento por encima de dicho límite, deberá de
                        abonar según la tarifa vigente.
                      </small>
                    </Tooltip>
                  </p>
                </li>
                <li>
                  <Icon icon="family-darkblue" css="icon--sm"></Icon>

                  <div>
                    <p className="h5.2">Pack familia con tarifa plana </p>
                    <p>De 3 a 6 asegurados. A partir del 7º, cada asegurado tendrá un coste de 5 € adicionales.</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="click-cards__prices">
              <div className="click-cards__prices-item">
                <p className="h6-price individual">
                  Precio individual{" "}
                  <button type="button" className="milenium-price-tooltip">
                    <p className="link">(3)</p>
                  </button>
                  <Tooltip anchorSelect=".milenium-price-tooltip" place="top" events={["click"]}>
                    <small className="react-tooltip__content">
                      Prima neta mensual por asegurado a la que se aplicará el recargo del Consorcio de Compensación de
                      Seguros (0,15% sobre la prima neta anual. Primas válidas para contrataciones formalizadas entre el
                      01/01/2025. y el 31/12/2025, ambos inclusive. Primas netas aplicables hasta el 01/12/2025. Primas
                      válidas para pólizas independientes. Permanencia anual.
                    </small>
                  </Tooltip>
                </p>
                <p className="h6-offer">
                  Precio sin descuento <s>19,95€</s>{" "}
                </p>
                <p className="h3.2">{premiumPrice}€</p>
              </div>
              <hr></hr>
              <div className="click-cards__prices-item">
                <p className="h6-price family">Pack familia</p>
                <p className="h6-offer">
                  Precio sin descuento <s>52,90€</s>{" "}
                </p>
                <p className="h3.2">{packPrice}€</p>
                <p className="p-extra">3-6 asegurados</p>
              </div>
            </div>
          </ClickCard>
        </List>
        <ProgressSteps value={1} max={4} />
      </div>
    </>
  );
};
