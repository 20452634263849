import { Icon, LinkSimple } from "getbasecore/Atoms";
import { BtnSimple } from "../../atoms/BtnSimple/BtnSimple";

import { useFormContext } from "../../../contexts/FormContext";
import { useState } from "react";
import { useValidations } from "services/validations";
import { ProgressSteps } from "../../atoms/ProgressSteps/ProgressSteps";
import { Form } from "getbasecore/Molecules";
import { FormInputSimple } from "../../atoms/FormInputSimple/FormInputSimple";
import { FormSelectSimple } from "../../atoms/FormInputSimple/FormSelectSimple";
import { Hero } from "../../organisms/Hero/hero";
import { Card } from "../../organisms/Card/Card";
import { streetTypes, allBusiness } from "../../../data/constants";
import "./step3.scss";
export const Step3 = ({
  onNext,
  errors,
  handleInputChange,
  formDataAddPerson,
  showForm,
  getBeneficiaryIndex,
  handleAddPerson,
  handleInputChangeAddPerson,
  handleRemovePerson,
  handleBack,
  handleBlur,
  handleBlurBeneficiary,
  citySearch,
  errorsBeneficiary,
}) => {
  const { formData, totalPrice } = useFormContext();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const noBeneficiaries = formData.selectedOption === "no" && formDataAddPerson.length === 0;

  const [isValid, setIsValid] = useState(false);
  const { setErrors } = useValidations();
  const handleValidation = () => {
    setErrors(validationsTotal(formData));
  };
  const validationsTotal = () => {
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜüÇç\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexDni =
      /^[XYZxyz]?[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]$/i;
    let regexPhone =
      /^(((\+|00)34)?([6-7][0-9]{8}|[8-9][1-9][0-9]{7})|(\+|00)376([3-46][0-9]{5}|[7-8][0-9]{5})|(\+|00)(?!(34|376|212|351|33|49|44|350))[0-9]{4,16})$/;
    let regexZipCode = /^(0[1-9]|[1-9][0-9])[0-9]{3}$/;

    if (!formData.business && (formData.business === "" || formData.business === "-1")) {
      errors.business = "Por favor, selecciona un 'Área de negocio'";
    }
    if (!formData.firstName.trim()) {
      errors.firstName = "El campo 'Nombre' es necesario";
      errors.firstName = "El 'Nombre' es necesario";
    } else if (!regexName.test(formData.firstName.trim())) {
      errors.firstName = "Introduce un 'Nombre' válido";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "El campo 'Apellido' es necesario";
      errors.lastName = "El 'Apellido' es necesario";
    } else if (!regexName.test(formData.lastName.trim())) {
      errors.lastName = "Introduce un 'Apellido' válido";
    }
    if (!formData.birthDate.trim()) {
      errors.birthDate = "El campo 'Fecha de nacimiento' es necesario";
      errors.birthDate = "La 'Fecha de nacimiento' es necesaria";
    }
    if (!formData.dni.trim()) {
      errors.dni = "El campo 'DNI' es necesario";
      errors.dni = "El 'DNI' es necesario";
    } else if (!regexDni.test(formData.dni.trim())) {
      errors.dni = "Introduce un 'DNI' válido";
    }
    if (!formData.phone.trim()) {
      errors.phone = "El campo 'Teléfono' es necesario";
    } else if (!regexPhone.test(formData.phone.trim())) {
      errors.phone = "Introduce un 'Teléfono' válido";
    }
    if (!formData.email.trim()) {
      errors.email = "El campo 'Email' es necesario";
      errors.email = "El 'Email' es necesario";
    } else if (!regexEmail.test(formData.email.trim())) {
      errors.email = "Introduce un 'Email' válido";
    }
    if (!formData.streetType && (formData.streetType === "" || formData.streetType === "-1")) {
      errors.streetType = "Por favor, selecciona un 'Tipo de vía'";
    }
    if (!formData.streetAddress.trim()) {
      errors.streetAddress = "El 'Nombre de la vía' es necesario";
    }
    if (!formData.gender.trim()) {
      errors.gender = "El campo 'Sexo' es necesario";
    }
    if (!formData.streetNumber.trim()) {
      errors.streetNumber = "El 'Nº' es necesario";
    }
    if (!formData.zip.trim()) {
      errors.zip = "El 'Código postal' es necesario";
    } else if (!regexZipCode.test(formData.zip.trim())) {
      errors.zip = "Introduce un 'Código postal' válido";
    }
    if (!formData.city.trim()) {
      errors.city = "La 'Localidad' es necesaria";
    } else if (!regexName.test(formData.city.trim())) {
      errors.city = "Introduce una 'Localidad' válida";
    }
  };

  const onValidation = () => {
    if (noBeneficiaries) {
      setShowErrorMessage(true);
    } else if (!noBeneficiaries) {
      setShowErrorMessage(false);
    }

    // Validar el formulario
    if (isValidForm()) {
      // Si el formulario es válido, cambiar el estado de isValid a true
      setIsValid(true);
      onNext();
    } else {
      // Si el formulario no es válido, cambiar el estado de isValid a false
      setIsValid(false);
      handleValidation("business", formData.business);
      handleValidation("firstName", formData.firstName);
      handleValidation("lastName", formData.lastName);
      handleValidation("birthDate", formData.birthDate);
      handleValidation("dni", formData.dni);
      handleValidation("phone", formData.phone);
      handleValidation("email", formData.email);
      handleValidation("gender", formData.gender);
      handleValidation("streetType", formData.streetType);
      handleValidation("streetAddress", formData.streetAddress);
      handleValidation("streetNumber", formData.streetNumber);
      handleValidation("zip", formData.zip);
      handleValidation("city", formData.city);
    }
  };
  const isValidForm = () => {
    // Función para calcular la edad a partir de la fecha de nacimiento
    function calculateAge(birthDateStr) {
      const today = new Date();
      const birthDate = new Date(birthDateStr);
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    // Validación de los datos personales del tomador de la póliza
    const isTomadorValid =
      formData.business !== "" &&
      formData.business !== "-1" &&
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      formData.birthDate !== "" &&
      calculateAge(formData.birthDate) >= 18 && // El campo birthDate no puede estar vacío y la persona debe ser mayor o igual a 18 años
      formData.dni !== "" &&
      formData.phone !== "" &&
      formData.email !== "" &&
      formData.gender !== "" &&
      formData.selectedOption !== "" &&
      formData.streetAddress !== "" &&
      formData.streetNumber !== "" &&
      formData.zip !== "" &&
      formData.city !== "" &&
      formData.streetType !== "";

    // Verificar si selectedOption es "no" y no hay datos de beneficiarios
    // Si no hay beneficiarios y selectedOption es "no", el formulario no es válido
    if (noBeneficiaries) {
      return false;
    }

    // Si no hay beneficiarios, solo se valida el tomador
    if (!showForm) {
      return isTomadorValid;
    }

    // Validación de los beneficiarios si se han añadido
    const areBeneficiariesValid = formDataAddPerson.every((beneficiary) => {
      // Verifica si el beneficiario tiene más de 14 años
      const today = new Date();
      const birthDate = new Date(beneficiary[`birthDate${beneficiary.id}`]);
      const age = today.getFullYear() - birthDate.getFullYear();
      const isBeneficiaryOver14 = age > 14;

      // Si es menor de 14 años, el DNI no es obligatorio
      if (!isBeneficiaryOver14) {
        return (
          beneficiary[`firstName${beneficiary.id}`] !== "" &&
          beneficiary[`lastName${beneficiary.id}`] !== "" &&
          beneficiary[`birthDate${beneficiary.id}`] !== "" &&
          beneficiary[`gender${beneficiary.id}`] !== "" &&
          beneficiary[`relationship${beneficiary.id}`] !== ""
        );
      } else {
        // Si es mayor de 14 años, se requiere el DNI
        return (
          beneficiary[`firstName${beneficiary.id}`] !== "" &&
          beneficiary[`lastName${beneficiary.id}`] !== "" &&
          beneficiary[`birthDate${beneficiary.id}`] !== "" &&
          beneficiary[`relationship${beneficiary.id}`] !== "" &&
          beneficiary[`gender${beneficiary.id}`] !== "" &&
          beneficiary[`dni${beneficiary.id}`] !== ""
        );
      }
    });

    return isTomadorValid && areBeneficiariesValid;
  };

  return (
    <>
      <Hero
        css={"step3"}
        imgSrc={"img/logosanitassinfondoazul.png"}
        securance={formData.securance}
        price={totalPrice.toFixed(2) + "€"}></Hero>
      <Form css="form form--simple" required>
        <Card css="container container--grid step">
          <LinkSimple css="button-back" aria="" onClick={handleBack}>
            <Icon css="icon--xs" icon="arrow-left" /> Volver
          </LinkSimple>
          <div>
            <p className="h3"id="titleStep3">3. Rellena tus datos personales</p>
          </div>
          <fieldset className="fieldset-business">
            <FormSelectSimple
              label="Selecciona tu área de negocio *"
              id="business"
              name="business"
              hideDefault={formData.business}
              required
              validation="business"
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {allBusiness.map((business, index) => (
                <option key={index} value={business} selected={business === formData.business}>
                  {business}
                </option>
              ))}
            </FormSelectSimple>
            {errors.business && <p className="error">{errors.business}</p>}
          </fieldset>
          <fieldset className="fieldset-personalData">
            <legend className="h5">Datos tomador de la póliza</legend>
            <FormInputSimple
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              placeholder="Nombre *"
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.firstName && <p className="error">{errors.firstName}</p>}
            </FormInputSimple>
            <FormInputSimple
              label=""
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              placeholder="Apellidos *"
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.lastName && <p className="error">{errors.lastName}</p>}
            </FormInputSimple>
            <FormInputSimple
              label=""
              type="date"
              name="birthDate"
              id="birthDate"
              value={formData.birthDate}
              placeholder="Fecha de nacimiento *"
              max={new Date().toJSON().slice(0, 10)}
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.birthDate && <p className="error">{errors.birthDate}</p>}
            </FormInputSimple>
            <FormInputSimple
              label=""
              type="text"
              name="dni"
              id="dni"
              value={formData.dni}
              placeholder="DNI *"
              required
              maxLength={9} // You can adjust the length according to your country's rules
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.dni && <p className="error">{errors.dni}</p>}
            </FormInputSimple>

            <FormInputSimple
              label=""
              type="tel"
              name="phone"
              id="phone"
              value={formData.phone}
              placeholder="Teléfono *"
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.phone && <p className="error">{errors.phone}</p>}
            </FormInputSimple>
            <FormInputSimple
              label=""
              type="email"
              name="email"
              id="email"
              value={formData.email}
              placeholder="Email *"
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.email && <p className="error">{errors.email}</p>}
            </FormInputSimple>
            <fieldset className="gender">
              <legend className="h6">
                Sexo *{" "}
              </legend>

              <div className="form__group radio-inputs">
                <div className="radio-simple">
                  <input
                    id="tomador-gender-female"
                    value="female"
                    name="gender"
                    type="radio"
                    checked={formData.gender === "female"}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label htmlFor={`tomador-gender-female`}>Mujer</label>
                </div>
                <div className="radio-simple">
                  <input
                    id="tomador-gender-male"
                    value="male"
                    name="gender"
                    type="radio"
                    checked={formData.gender === "male"}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label htmlFor={`tomador-gender-male`}>Hombre</label>
                </div>
              </div>
              {errors.gender && <p className="error">{errors.gender}</p>}
            </fieldset>
            <fieldset>
              <legend className="h6">¿El tomador es el primer asegurado?*
              </legend>
              <div className="form__group radio-inputs">
                <div className="radio-simple">
                  <input
                    id="yes"
                    value="yes"
                    name="selectedOption"
                    type="radio"
                    checked={formData.selectedOption === "yes"}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label htmlFor="yes">Sí</label>
                </div>
                <div className="radio-simple">
                  <input
                    id="no"
                    value="no"
                    name="selectedOption"
                    type="radio"
                    checked={formData.selectedOption === "no"}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
              {showErrorMessage &&
                <p className="error">Si el tomador no es beneficiario por favor
                  añade alguno</p>}
            </fieldset>
          </fieldset>
          <fieldset className="fieldset-direction">
            <legend className="h5">Dirección</legend>
            <fieldset className="fieldset-streetType">
              <FormSelectSimple
                label="Tipo de vía *"
                id="streetType"
                name="streetType"
                hideDefault={formData.streetType}
                required
                validation="streetType"
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleInputChange(e)}>
                {streetTypes.map((streetType, index) => (
                  <option key={index} value={streetType}
                          selected={streetType === formData.streetType}>
                    {streetType}
                  </option>
                ))}
              </FormSelectSimple>
              {errors.streetType &&
                <p className="error">{errors.streetType}</p>}
            </fieldset>
            <FormInputSimple
              type="text"
              name="streetAddress"
              id="streetAddress"
              value={formData.streetAddress}
              placeholder="Nombre de la vía *"
              label=""
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.streetAddress &&
                <p className="error">{errors.streetAddress}</p>}
            </FormInputSimple>
            <FormInputSimple
              type="text"
              name="streetNumber"
              id="streetNumber"
              value={formData.streetNumber}
              placeholder="Nº *"
              label=""
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.streetNumber && <p className="error">{errors.streetNumber}</p>}
            </FormInputSimple>
            <FormInputSimple
              type="text"
              name="flat"
              id="flat"
              value={formData.flat}
              placeholder="Piso, puerta.."
              label=""
              onChange={(e) => handleInputChange(e)}
            />
            <FormInputSimple
              type="text"
              name="zip"
              id="zip"
              value={formData.zip}
              maxLength={5}
              placeholder="CP *"
              label=""
              required
              onChange={(e) => citySearch(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.zip && <p className="error">{errors.zip}</p>}
            </FormInputSimple>
            <FormInputSimple
              type="text"
              name="city"
              id="city"
              value={formData.city}
              placeholder="Localidad *"
              label=""
              required
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {" "}
              {errors.city && <p className="error">{errors.city}</p>}
            </FormInputSimple>
          </fieldset>
        </Card>
        {showForm &&
          formDataAddPerson.map(
            (
              data,
              index // Renderiza un formulario para cada conjunto de datos en el estado formDataAddPerson
            ) => (
              <div key={data.id} className="container-beneficiary">
                <Card>
                  <div className="container-titleBeneficiary">
                    <legend className="h5">Datos del beneficiario {getBeneficiaryIndex(index)}</legend>
                    <LinkSimple css="link" onClick={() => handleRemovePerson(data.id)}>
                      <Icon css="icon--xxs" icon="trash"></Icon>Eliminar beneficiario
                    </LinkSimple>
                  </div>
                  <fieldset className="fieldset-beneficiary">
                    <FormInputSimple
                      type="text"
                      name={`firstName${data.id}`}
                      id={`firstName${data.id}`}
                      value={data[`firstName${data.id}`]}
                      placeholder="Nombre *"
                      required
                      onChange={(e) => handleInputChangeAddPerson(index, e)} // Maneja el cambio en los campos del formulario
                      onBlur={(e) => handleBlurBeneficiary(index, e)}>
                      {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`firstName${data.id}`] && (
                        <p className="error">{errorsBeneficiary[data.id][`firstName${data.id}`]}</p>
                      )}{" "}
                    </FormInputSimple>
                    <FormInputSimple
                      type="text"
                      name={`lastName${data.id}`}
                      id={`lastName${data.id}`}
                      value={data[`lastName${data.id}`]}
                      placeholder="Apellidos *"
                      required
                      onChange={(e) => handleInputChangeAddPerson(index, e)}
                      onBlur={(e) => handleBlurBeneficiary(index, e)}>
                      {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`lastName${data.id}`] && (
                        <p className="error">{errorsBeneficiary[data.id][`lastName${data.id}`]}</p>
                      )}{" "}
                    </FormInputSimple>
                    <FormInputSimple
                      type="date"
                      name={`birthDate${data.id}`}
                      id={`birthDate${data.id}`}
                      value={data[`birthDate${data.id}`]}
                      placeholder="Fecha de nacimiento *"
                      max={new Date().toJSON().slice(0, 10)}
                      required
                      onChange={(e) => handleInputChangeAddPerson(index, e)}
                      onBlur={(e) => handleBlurBeneficiary(index, e)}>
                      {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`birthDate${data.id}`] && (
                        <p className="error">{errorsBeneficiary[data.id][`birthDate${data.id}`]}</p>
                      )}{" "}
                    </FormInputSimple>
                    <FormInputSimple
                      type="text"
                      name={`dni${data.id}`}
                      id={`dni${data.id}`}
                      value={data[`dni${data.id}`]}
                      placeholder="DNI *"
                      required
                      maxLength={9}
                      onChange={(e) => handleInputChangeAddPerson(index, e)}
                      onBlur={(e) => handleBlurBeneficiary(index, e)}>
                      <p className="comments">No obligatorio para menores de 14 años</p>
                      {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`dni${data.id}`] && (
                        <p className="error">{errorsBeneficiary[data.id][`dni${data.id}`]}</p>
                      )}{" "}
                    </FormInputSimple>
                    <FormInputSimple
                      type="text"
                      name={`relationship${data.id}`}
                      id={`relationship${data.id}`}
                      value={data[`relationship${data.id}`]}
                      placeholder="Parentesco con el tomador *"
                      required
                      onChange={(e) => handleInputChangeAddPerson(index, e)}
                      onBlur={(e) => handleBlurBeneficiary(index, e)}>
                      {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`relationship${data.id}`] && (
                        <p className="error">{errorsBeneficiary[data.id][`relationship${data.id}`]}</p>
                      )}{" "}
                    </FormInputSimple>
                    <fieldset>
                      <legend className="h6">
                        Sexo *{" "}
                        {errorsBeneficiary[data.id] && errorsBeneficiary[data.id][`gender${data.id}`] && (
                          <p className="error">{errorsBeneficiary[data.id][`gender${data.id}`]}</p>
                        )}{" "}
                      </legend>

                      <div className="form__group radio-inputs">
                        <div className="radio-simple">
                          <input
                            id={`female${data.id}`}
                            value="female"
                            name={`gender${data.id}`}
                            type="radio"
                            checked={data[`gender${data.id}`] === "female"}
                            onChange={(e) => handleInputChangeAddPerson(index, e)}
                          />
                          <label htmlFor={`female${data.id}`}>Mujer</label>
                        </div>
                        <div className="radio-simple">
                          <input
                            id={`male${data.id}`}
                            value="male"
                            name={`gender${data.id}`}
                            type="radio"
                            checked={data[`gender${data.id}`] === "male"}
                            onChange={(e) => handleInputChangeAddPerson(index, e)}
                          />
                          <label htmlFor={`male${data.id}`}>Hombre</label>
                        </div>
                      </div>
                    </fieldset>
                  </fieldset>
                </Card>
              </div>
            )
          )}
        <Card>
          <BtnSimple
            type="button"
            css="btn-simple--3 addBeneficiary"
            id="addBeneficiary"
            aria=""
            onClick={handleAddPerson}>
            {" "}
            + Añadir otro beneficiario
          </BtnSimple>
        </Card>
      </Form>
      <Card>
        <ProgressSteps value={3} max={4}>
          <BtnSimple
            id="nextStep3"
            type="button"
            css={isValidForm() === false ? "btn-simple--1 invalid" : "btn-simple--1"}
            aria="Siguiente"
            onClick={onValidation}>
            Siguiente<Icon css="icon--xs" icon="arrow-right"></Icon>
          </BtnSimple>
        </ProgressSteps>
      </Card>
    </>
  );
};
