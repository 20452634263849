
export const securanceMilenium = "Seguro Dental Milenium";
export const priceMilenium2025 = 7.27;

export const securancePremium = "Seguro Dental Premium";
export const pricePremium2025 = 12.66;
export const pricePremiumExtra = 5;
export const pricePack2025 = 32.42;

export const months = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
];

export const years = ["2025"];

export const streetTypes = ["Calle", "Avenida", "Pasaje", "Camino", "Carretera", "Bulevar", "Paseo", "Travesía"];

export const allBusiness = ["Seguros", "Hospitales", "Dental", "Mayores"];
export const allFrecuencies = ["Mes", "Trimestre", "Semestre", "Año"];
